import React, { useState } from "react";
import "./contact.css";
import { useRef } from "react";
import { CiLocationOn } from "react-icons/ci";
import { TfiEmail } from "react-icons/tfi";
import { BsPhone } from "react-icons/bs";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import WhatsApp from "../images/whats-app.png";

const Contact = () => {
  const [submitted, setSubmitted] = useState(false);

  const form = useRef();
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_x9fu9ka",
        "template_9yfromh",
        form.current,
        "eurSS5woWB2TCAJr_"
      )
      .then(
        (result) => {
          console.log(result.text);
          console.log("message sent");
        },
        (error) => {
          console.log(error.text);
        }
      );
    toast.success("Our Team will soon reach out to you. :)", { position: "bottom-right" });
    setSubmitted(true);
  };

  return (
    <>
      <div className="container" id="contact">
        <div className="label-1 pt-5">
          <h4 className="pt-5">CONTACT</h4>
          <hr />
        </div>
        <div className="label-2">CONTACT US</div>

        <div className="contact_box col-lg-11">
          <div className="contact col-lg-4">
            {/* <div className="contact_box1 col-lg-12">
              <div className="inner_contact_box1 col-lg-2">
                <CiLocationOn className="contact_icon" />
              </div>
              <div className="inner_contact_box2">
                <h4>Location:</h4>
                <p>A108 Adam Street, New York, NY 535022</p>
              </div>
            </div> */}

            <div className="contact_box1 col-lg-12 pt-5">
              <div className="inner_contact_box1 col-lg-2">
                <TfiEmail className="contact_icon" />
              </div>
              <div className="inner_contact_box2">
                <h4>Email:</h4>
                <p>info@pilotpreparationacademy.com</p>
              </div>
            </div>

            <div className="contact_box1 col-lg-12 pt-5">
              <div className="inner_contact_box1 col-lg-2">
                <BsPhone className="contact_icon" />
              </div>
              <div className="inner_contact_box2">
                <h4>Call:</h4>
                <p>
                  +91-9301657163 (Rishi Chaudhary)
                </p>
              </div>
            </div>
          </div>
          {!submitted ? (
            <form className="col-lg-9" ref={form} onSubmit={sendEmail}>
              <div className="input_name_email">
                <input placeholder="Your Name" name="first_name" className="input_name"></input>
                <input placeholder="Your Email" name="user_email" className="input_email"></input>
              </div>

              <input
                name="number"
                placeholder="Phone Number"
                className="input_subject col-lg-12"
              ></input>

              <textarea
                name="message"
                type="desc"
                placeholder="Message"
                className="input_message col-lg-12"
              ></textarea>

              <div className="contact_btn">
                <button className="send_btn" type="submit" value="Send">
                  Send Message
                </button>
              </div>
            </form>
          ) : (
            <div className="join_box">
              <div className="inner_join1">
                Join the Pilot Preparation Academy WhatsApp community,
                where aspiring and established pilots come together for
                the latest updates and engaging discussions. Join us now!
              </div>
              <div className="inner_join2">
                <img
                  className="WhatsApp"
                  src={WhatsApp}
                  alt="team image"
                />
                <p>Our WhatsApp Community:</p>
                <a href="https://chat.whatsapp.com/DbGhKlZfMYFJttiBdNV6y3">
                  <button>Join</button>
                </a>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};


export default Contact;
